<template>
  <div id="SignUp">
    <div class="main-theme">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230605142442084.png" />
    </div>
    <div class="form_view">
      <div class="sub-theme">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230605142426522.png" />
      </div>
      <div class="mini_title"><span>报名须知</span></div>
      <div class="rule-cont">
        <div class="rule-list">
          <div class="rule-item">
            <span class="num">1</span>
            <div class="content">报名时间：2023/06/08-2023/07/20。</div>
          </div>
          <div class="rule-item"><span class="num">2</span>
            <div class="content">旅游活动结束后，即可按以下方式退回酒店定位费【2选1】：
              <div class="fee-list">
                <p>● 店主采购券（价值1000元）</p>
                <p>● 产品礼包（价值1000元）</p>
              </div>
              <div class="gift-bag-content">
                <p>礼包明细:</p>
                <p>蔓薇黑金阻燃咖啡(15g*14)*3盒</p>
                <p>蜜乐儿蜜粉闺礼-面膜集锦*2盒(马卡龙3片+御、养各一片）</p>
                <p>梵洁诗温泉水醒肤喷雾*1瓶</p>
              </div>
            </div>
          </div>
          <div class="rule-item"><span class="num">3</span>
            <div class="content">本次报名仅限年满18岁-60岁人群报名，18岁以下及60岁以上报名另行通知。</div>
          </div>
          <div class="rule-item"><span class="num">4</span>
            <div class="content">报名期间，如遇特殊情况，可申请取消报名，支付定金原路返还；因公司需要提前预定旅游服务，7月20号后将不能取消报名，已付定金不支持退款。</div>
          </div>
          <div class="rule-item"><span class="num">5</span>
            <div class="content">四川蜜蛋网络科技有限公司&蔓薇品牌在法律规定的范围内对本次报名有最终解释权。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns" v-if="info.existsApply > 0">
      <div @click="toList()" class="btns_item">我的报名</div>
      <div @click="toJion(2)" class="btns_item btns_item_2"  v-if="info.isCanSign">继续报名</div>
      <van-button round color="#ccc" v-else>继续报名</van-button>
    </div>
    <div class="btns" v-else>
      <van-button round color="#209DFF" @click="toJion(1)" v-if="info.isCanSign">即刻报名</van-button>
      <van-button round color="#ccc" v-else>即刻报名</van-button>
    </div>
  </div>
</template>

<script>
import yellowTitle from "./components/yellowTitle";
import { replaceAppToken, deliveryMessage } from "../../../utils/appUtil";

export default {
  name: "MiSignUp",
  components: {
    yellowTitle
  },
  data() {
    return {
      tabIndex: 0,
      arr: [],
      info: '',
      type: 1
    }
  },
  created() {
    this.Init()
  },
  methods: {
    Init() {
      this.post('/OnlyOnce/AnnualMeeting/GetUserDetails', {
        source: 3
      }, true, 2).then(data => {
        if (data.code === -1) return;
        if (data.code === 0) {
          return this.$dialog.alert({ title: '提示', message: data.msg }).then(() => {
            this.$router.push("/")
          })
        }
        if (!data.response) {
          return this.$dialog.alert({ title: '提示', message: "该账号信息异常，请联系客服" }).then(() => {
            this.$router.push("/")
          })
        }
        this.info = data.response;
      })
    },
    toJion(e) {
      if (!this.info) {
        return this.$dialog.alert({ title: '提示', message: '网络繁忙，请稍后' })
      }
      this.$router.push(`/miInformationFill/${e}`)
    },
    toList() {
      if (this.info.existsApply <= 0) {
        return this.$dialog.alert({
          title: '提示',
          message: '暂无任何报名信息'
        })
      }
      this.$router.push(`/miMeetingList?`)
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#SignUp {
  width: 100%;
  font-size: 0px;
  padding-bottom: 30px;
  background-color: #1AC1F3;

  .main-theme {
    img {
      width: 100%;
    }
  }

  .back_img {
    width: 100%;
    position: relative;
    bottom: 64px;
  }

  .video_view {
    margin-left: 15px;
    margin-right: 15px;
    height: 185px;
    margin-top: 75px;
    background-color: #000;
    position: relative;
    /*position: absolute;*/
    /*z-index: 1;*/
    /*top: 96px;*/
    box-sizing: border-box;
    border: 5px solid #fff;
  }

  .form_view {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #fff;
    position: relative;
    padding: 60px 20px 30px;
    box-sizing: border-box;
    border-radius: 15px;
    border: 5px solid #9AD0FF;
    padding-bottom: 12px;
  }

  .sub-theme {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;

    img {
      width: 288px;
    }
  }

  .mini_title {
    color: #51A6F0;
    text-align: center;
    position: relative;
    font-weight: bold;
    font-size: 13px;

    span {
      position: relative;
      z-index: 10;
      display: inline-block;
      padding: 11px 30px;
      background-color: #fff;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 200px;
      height: 1px;
      background-color: #51A6F0;
      left: calc(50% - 100px);
    }
  }

  .account-cont {
    display: flex;
    padding: 11px 0;
    align-items: center;
    justify-content: space-between;
  }

  .integral_box {
    display: flex;
    justify-content: center;
    font-size: 18px;
    flex-wrap: wrap;
    align-items: center;

    .integral_title {
      color: #666;
      width: 100%;
      font-size: 12px;
      line-height: 20px;
    }

    .integral_icon {
      width: 18px;
      height: 18px;
      margin-left: 5px;
      position: relative;
      bottom: 2px;
    }

    .integral_num {
      color: #f72fa8;
      font-weight: bold;
      font-size: 27px;
      position: relative;
      top: 2px;
    }
  }

  .fee-table {
    padding: 15px 0;
    text-align: left;

    .van-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #999999;

      .van-col {
        float: none;
        color: #666;
        padding: 10px;
        font-size: 12px;
        border-left: 1px solid #999;

        .item {
          margin: 0 -10px;
          padding: 10px;
          border-bottom: 1px solid #999;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }
        }

        &:first-child {
          border-left: none;
        }
      }

      &:first-child {
        border-top: 1px solid #999999;
      }
    }
  }

  .rule-cont {
    color: #666;
    font-size: 12px;
    text-align: left;
    line-height: 20px;
    letter-spacing: 1px;
    padding-bottom: 10px;

    .main-title {
      text-align: center;
      padding: 10px 0;
      color: #f0169a;
      font-size: 13px;
      font-weight: bold;
    }

    .van-divider {
      color: #666;
      font-size: 12px;
      margin: 10px 15%;
    }

    .explain {
      text-indent: 20px;
      padding-left: 21px;
    }

    .user-title {
      font-weight: bold;
      padding-left: 21px;
    }

    .rule-list {
      padding-bottom: 10px;

      .rule-item {
        display: flex;


        .num {
          width: 13px;
          height: 13px;
          color: #fff;
          font-size: 10px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          margin-right: 8px;
          justify-content: center;
          background-color: #666666;
        }

        .content {
          flex: 1;
          color: #444;
          font-size: 12px;
        }
      }
    }

    .gift-bag-content {
      color: #56A8F1;
    }
  }

  .line {
    height: 1px;
    background-color: #f1f1f1;
  }

  .btns {
    display: flex;
    padding-top: 20px;
    justify-content: center;

    .van-button {
      width: 140px;
      height: 34px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .btns_item {
    width: 140px;
    height: 29px;
    border-radius: 27px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.0;
    margin-right: 15px;
    background-color: #209DFF;
  }

  .btns_item_2 {
    background-image: none;
    color: #209DFF;
    margin-right: 0;
    border: 1px solid #fff;
    background-color: #fff;
  }
}
</style>